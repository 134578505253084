<template>
  <img :src="params"
       alt
       class="content-view__image"/>
</template>

<script>
export default {
  name: 'TripHeroImage',
  props: {
    params: {
      type: String,
      required: true,
    },
  },
};
</script>
